import type {
    ButtonProps,
    InputGroupProps,
    InputProps,
} from '@chakra-ui/react';
import {
    Button,
    Icon,
    InputGroup,
    InputLeftElement,
    InputRightElement,
} from '@chakra-ui/react';
import {
    KeyReturn as IconEnter,
    X as IconReset,
    MagnifyingGlass as IconSearch,
} from '@phosphor-icons/react';
import * as React from 'react';
import { TrimmedInput } from './trimmed-input';

export interface SearchBoxProps extends Omit<InputGroupProps, 'onSubmit'> {
    initialValue?: string;
    onSubmit: (searchString: string) => unknown;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => unknown;
    isInstant?: boolean;
    placeholder?: string;
    boxHeight?: string;
    iconSpace?: InputProps['pl'];
    searchButtonProps?: ButtonProps;
    disableFocusHotkey?: boolean;
}

export const SearchBox = React.forwardRef<HTMLInputElement, SearchBoxProps>(
    (
        {
            initialValue,
            onSubmit,
            onKeyDown,
            isInstant = false,
            placeholder = 'search',
            boxHeight = '9',
            iconSpace = '40px',
            searchButtonProps,
            disableFocusHotkey = false,
            ...inputGroupProps
        },
        forwardedRef
    ) => {
        const inputRef = React.useRef<HTMLInputElement>(null);

        // Use either local input ref of the forwarded ref
        // Refs: https://stackoverflow.com/questions/62238716/using-ref-current-in-react-forwardref
        React.useImperativeHandle(
            forwardedRef,
            () => inputRef.current as HTMLInputElement
        );

        const [value, setValue] = React.useState<string>(initialValue || '');

        // Listen to custom event: 'focusSearch' and focus on the input
        React.useEffect(() => {
            if (disableFocusHotkey) {
                return;
            }

            const focus = () => inputRef.current?.focus();
            document.addEventListener('focusSearch', focus);

            return () => {
                document.removeEventListener('focusSearch', focus);
            };
        }, [disableFocusHotkey]);

        const handleSubmit = () => onSubmit(value);
        const handleReset = () => {
            setValue('');
            onSubmit('');
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setValue(value);
            isInstant && onSubmit(value);
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                handleSubmit();
            } else if (e.code === 'Escape') {
                handleReset();
            }

            onKeyDown && onKeyDown(e);
        };

        return (
            <InputGroup size="md" rounded="md" {...inputGroupProps}>
                <InputLeftElement
                    h={boxHeight}
                    pointerEvents="none"
                    children={<Icon as={IconSearch} color="gray.500" />}
                />
                <TrimmedInput
                    ref={inputRef}
                    autoComplete="off"
                    placeholder={placeholder}
                    h={boxHeight}
                    rounded="inherit"
                    pl={iconSpace}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    value={value}
                />

                {value && (
                    <InputRightElement
                        width={!isInstant ? '135px' : undefined}
                        padding="0"
                        h="100%"
                    >
                        <Icon
                            size="2"
                            mr="3"
                            as={IconReset}
                            aria-label="reset"
                            bg="transparent"
                            opacity="0.6"
                            rounded="sm"
                            _hover={{ bg: 'blackAlpha.200' }}
                            onClick={handleReset}
                        />

                        {!isInstant && (
                            <Button
                                h="1.75rem"
                                size="sm"
                                onClick={handleSubmit}
                                {...searchButtonProps}
                            >
                                Search{' '}
                                <IconEnter
                                    size="20px"
                                    style={{
                                        opacity: '0.8',
                                        marginLeft: '0.5em',
                                        marginTop: '-3px',
                                    }}
                                />
                            </Button>
                        )}
                    </InputRightElement>
                )}
            </InputGroup>
        );
    }
);

declare global {
    type FocusSearchEvent = CustomEvent<unknown>;

    interface DocumentEventMap {
        focusSearch: FocusSearchEvent;
    }
}
